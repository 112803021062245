import React from "react"
import { graphql } from "gatsby"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import Footer from "../components/Footer"
import ContentImage from "../components/ContentImage"

const ctaNewsLetter = {
  hasBackground: true,
  hasButton: false,
  styleB: true,
  hasForm: true,
  submitValue: 'Sign Up',
  customClass: "cta-newsletter",
  title: "Sign up for the free newsletter",
  desc: "For exclusive strategies not found on the blog",
  image: require('../assets/images/blog-footer.png'),
}


export default function Template({ data }) {
  const { selectedBlog, allBlog } = data
  const { frontmatter, html } = selectedBlog
  const { edges } = allBlog

  console.log(data)
  console.log(edges)

  const dataArr = []
  dataArr.push(data)

  dataArr.push(...edges)

  console.log("dataArr ----------------")
  console.log(dataArr)

  dataArr.splice(0, 1)

  console.log("dataArr ----------------")
  console.log(dataArr)

  return (
    <>
      <OgTags
        ogTitle={frontmatter.title}
        ogDescription="Fullstack HQ Blogs."
        ogUrl={`https://fullstackhq.com${frontmatter.path}`}
        ogImage={`https://fullstackhq.com${frontmatter.thumbnail}`}
      />
      <Header />
      <main className="single-blog initialized-header">
        <div className="container">
          <div className="blog-post-container">
            <div className="blog-post">
              <h1 className="blog-post-title">{frontmatter.title}</h1>
              <p className="blog-post-date">{frontmatter.date}</p>
              <img className="blog-post-featured-img" src={frontmatter.thumbnail} alt="blog-img"/>
              <center>{frontmatter.featuredTitle}</center><br/>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
        <ContentImage content={ ctaNewsLetter }  />
        {/* {
          dataArr.map((item, i)  => {
            return(
              <div key={i}>
                <Link to={item.node.frontmatter.path}>
                  <br />
                  <br />
                  <p>{item.node.frontmatter.title}</p>
                  <p>{item.node.excerpt}</p>
                  <img src={item.node.frontmatter.thumbnail} alt="featured-props"/>
                  <br />
                  <br />
                </Link>
              </div>
            )
          })
        } */}
      </main>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    selectedBlog: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        featuredTitle
      }
    },
    allBlog: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            thumbnail
          },
          excerpt
        }
      }
    }
  }
`
